import React from 'react'

const Loader = () => {
  return (
    <>
    <div className="parent">
        <div className="container">
            <div className="row">
                <div className="image-parrent">
                  <img src="/images/logo.svg" alt="" />
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default Loader